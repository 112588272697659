<template>
    <b-alert class="mb-0"
      :show="dismissCountDown"
      fade
      :variant="alertvariant"
      @dismiss-count-down="countDownChanged"
      dismissible
    >
      {{ alert.text }}
    </b-alert>
</template>

<script>
  export default {
    props: {
      alert: {
        text: '',
        variant: 'info'
      }
    },
    data() {
      return {
        dismissCountDown: 0,
        alertvariant: "info",
      }
    },
    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
    },
    watch: {
      alert: {
      handler: function (newVal) {
        this.dismissCountDown = newVal.timeout ? newVal.timeout : 5;
        this.alertvariant =  newVal.variant ? newVal.variant : 'warning';
      },
      deep: true
    },
    }
  }
</script>