<template>
  <div id="app">
    <TopMenu/>
    <AlertBox :alert='alert'/>
    <div class="container-fluid p-0 bg-dark" >
      <div class="col-12 bg-dark mb-1" v-show='isEditable'>
        <b-button variant="success" @click="addRowHandler">Add Holiday</b-button>
      </div>
      <b-table
          id="fullTable"
          :fields="fields"
          :items="holidaySettings"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          small
          bordered
          dark
          responsive
      >
        <template #cell(name)="data">
          <b-form-input v-if="data.item.isEdit" type="text" v-model="data.item.name"/>
          <span v-else>{{data.item.name}}</span>
        </template>

          <template #cell(isWeekly)="data">
            <b-form-select :disabled="!data.item.isEdit" @change="onChangeWeekly(data.item)" v-model="data.item.isWeekly">
              <b-form-select-option value=0>No</b-form-select-option>
              <b-form-select-option value=1>Yes</b-form-select-option>
            </b-form-select>
          </template>
        <template #cell(weekdays)="data">
            <b-form-select v-if="data.item.isEdit&&data.item.isWeekly=='1'" v-model="data.item.weekdays" multiple :select-size=7 >
              <b-form-select-option value=1>Mon</b-form-select-option>
              <b-form-select-option value=2>Tue</b-form-select-option>
              <b-form-select-option value=3>Wed</b-form-select-option>
              <b-form-select-option value=4>Thur</b-form-select-option>
              <b-form-select-option value=5>Fri</b-form-select-option>
              <b-form-select-option value=6>Sat</b-form-select-option>
              <b-form-select-option value=7>Sun</b-form-select-option>
            </b-form-select>
            <span v-else>{{getWeekdayStr(data.item.weekdays)}}</span>
        </template>
        <template #cell(startDay)="data">
          <input v-if="data.item.isEdit&&data.item.isWeekly=='0'" type="date" v-model="data.item.startDay"/>
          <span v-else>{{data.item.startDay}}</span>
        </template>
        <template #cell(endDay)="data">
          <input v-if="data.item.isEdit&&data.item.isWeekly=='0'" type="date" v-model="data.item.endDay"/>
          <span v-else>{{data.item.endDay}}</span>
        </template>
        <template #cell(fromTime)="data">
          <input v-if="data.item.isEdit" type="time" v-model="data.item.fromTime"/>
          <span v-else>{{data.item.fromTime}}</span>
        </template>
        <template #cell(toTime)="data">
          <input v-if="data.item.isEdit" type="time" v-model="data.item.toTime"/>
          <span v-else>{{data.item.toTime}}</span>
        </template>
          <template #cell(symbols)="data">
            <SubSymbol  @change="onChangeSymbols" :isEdit="data.item.isEdit" :subSymbols='data.item.symbols' 
            :serverRecords='serverRecords' :symbolSettings='symbolSettings' :settingId='data.item.id'/>
          </template>
        <template #cell(edit)="data">
          <b-button @click="editRowHandler(data)" class="ml-1 mt-1 btn-sm">
            <span v-if="!data.item.isEdit"><feather type="settings"></feather></span>
            <span v-else><feather type="check"></feather></span>
          </b-button>
          <b-button @click="deleteRowHandler(data)" v-if="data.item.isEdit" class="ml-1 mt-1  btn-sm">
            <span><feather type="trash-2"></feather></span>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import TopMenu from '../components/TopMenu.vue'
import AlertBox from '../components/AlertBox.vue'
import SubSymbol from '../components/SubSymbol.vue'

export default {
  name: 'App',
  components: {
    TopMenu,
    AlertBox,
    SubSymbol
  },
  data() {
      return {
          isEditable: true,
          sortBy: 'name',
          sortDesc: true,
          fields: [
            {
                key:"name",
                sortable: true,
            },
            {
                key:"isWeekly",
            },
            {
                key:"weekdays",
            },
            {
                key:"startDay",
            },
            {
                key:"endDay",
            },
            {
                key:"fromTime",
            },
            {
                key:"toTime",
            },
            {
                key:"symbols",
            },
            {
                key:"edit",
            }
          ],
          symbolSettings : [],
          serverRecords: [],
          holidaySettings: [],      
          alert: {
            time: '',
            text: '',
            variant: 'info'
          },
          updateServerStatus: null
      }
  },
  async mounted() {
    this.filterUser();
    await this.fetchServerRecords();
    await this.fetchSymbolSettings();
    this.fetchHolidaySettings();
  },
    methods: {
    filterUser() {
      if(this.$store.getters.getSession.user.name != 'admin') {
        this.fields = this.fields.filter(field => field.key != 'edit');
        this.isEditable = false;
      }
    },
      async fetchServerRecords() {
      await this.$store.dispatch('fetchServerRecords').then((res) => {
        if(res.status == 200) {
          this.serverRecords = this.$store.getters.getServerRecords;
        } else this.alert = {text:'failed to get server records, reson=' + res.data, variant: 'danger'};
      });
    },
    async fetchSymbolSettings() {
      await this.$store.dispatch('fetchSymbolSettings').then((res) => {
        if(res.status == 200) {
          this.symbolSettings = this.$store.getters.getSymbolSettings;
        } else this.alert = {text:'failed to get Symbol Settings, reson=' + res.data, variant: 'danger'};
      });
    },
    fetchHolidaySettings() {
      this.$store.dispatch('fetchHolidaySettings').then((res) => {
        if(res.status == 200) {
          this.holidaySettings = this.$store.getters.getHolidaySettings.map(item => ({...item, isEdit: false}));
        } else this.alert = {text:'failed to get holiday settings, reson=' + res.data, variant: 'danger'};
      });
    },
    updateHolidaySettings() {
        this.$store.dispatch('updateHolidaySettings', this.holidaySettings).then((res) => {
          if(res.status == 200) {
            this.alert = {text:'Update successful', variant: 'success'};
          } else this.alert = {text:res.data, variant: 'danger'};
        });
    },
    editRowHandler(data) {
      data.item.isEdit = !data.item.isEdit;
      if(!data.item.isEdit) {
        this.updateHolidaySettings();
      }
    },
    deleteRowHandler(data) {
      if(confirm("Delete this record?"))
      {
        const indexOfObject = this.holidaySettings.findIndex(object => 
        { return object.id === data.item.id;});
        this.holidaySettings.splice(indexOfObject, 1);
        this.updateHolidaySettings();
      }
    },
    addRowHandler() {
      this.sortBy = '';
      let newRow = this.fields.reduce((a, c) => ({ ...a, [c.key]: null }), {});
      newRow.id = this.holidaySettings.reduce((a,c) => c.id > a ? c.id : a, 0) + 1;
      newRow.symbols = [];
      newRow.weekdays = [];
      newRow.fromTime = "09:00";
      newRow.toTime = "17:00";
      newRow.isWeekly = "0";
      const today = new Date().toJSON().slice(0,10);
      newRow.startDay = today;
      newRow.endDay = today;
      newRow.isEdit = true;
      this.holidaySettings.unshift(newRow);
    },
    onChangeSymbols(data) {
      let found = this.holidaySettings.find(s=> data.id == s.id)
      if(found) {
          found.symbols = data.settings;
      }
      else{
        console.log('invalid data in onChangeSymbols, data=');
        console.log(data);
      }
    },
    onChangeWeekly(item) {
      if(item.isWeekly == "1") {
        item.startDay = "";
        item.endDay = "";
      } else {
        const today = new Date().toJSON().slice(0,10);
        item.startDay = today;
        item.endDay = today;
        item.weekdays = [];
      }
    },
    getWeekdayStr(weekdays){
      return weekdays.map(x=>{
        if(x == "1") return "Mon";
        else if (x == "2") return "Tue";
        else if (x == "3") return "Wed";
        else if (x == "4") return "Thur";
        else if (x == "5") return "Fri";
        else if (x == "6") return "Sat";
        else if (x == "7") return "Sun";
      }).join(' / ');
    }
  },
  setup () {
    console.log("I am in setup!!!")
  }
}
</script>

<style>
  @import '../assets/styles/global.css';
</style>