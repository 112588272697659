<template>
<div>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand >Find Hse</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/dashboard"><feather type="list"></feather>Dashboard</b-nav-item>
        <b-nav-item href="/propertyGenerator" target="_blank"><feather type="help-circle"></feather>盤紙</b-nav-item>
        <b-nav-item href="itms-services://?action=download-manifest&url=https://findhse.cc/app/manifest.plist" target="_blank"><feather type="help-circle"></feather>app</b-nav-item>
        <b-nav-item to="/login"><feather type="settings"></feather>Sign Out</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<style scoped>
a.router-link-exact-active {
  color: lightBlue !important;
}
</style>

<script>


    export default {
        name: 'TopMenu',
        props: [],
        components: {
        },
        data() {
          return {
          }
        },
        mounted() {
        }
    }
</script>