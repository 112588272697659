<template>
<div>
  <b-button @click="onclick" variant="info" v-b-modal.modal="settingId.toString()">{{btnName}}</b-button>
  <b-modal
    :id="settingId.toString()"
    ref="modal"
    title="SubSymbols"
    :ok-only="!isEdit"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"  
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <div class="col-12 mb-2 p-0" >
          <b-button v-show="isEdit" variant="success" @click="addRowHandler">Add</b-button>
      </div>
      <b-table
        id="fullTable"
        :fields="fields"
        :items="newSubSymbols"
        small
        fixed
        bordered
        responsive
      >
        <template #cell(serverName)="data">
          <b-form-select :disabled='!isEdit' v-model="data.item.serverId" :options="serverOptions"></b-form-select>
        </template>
        <template #cell(symbol)="data">
          <b-form-select :disabled='!isEdit' v-model="data.item.symbol" :options="getSymbolOptions(data.item.serverId)" ></b-form-select>
        </template>
        <template #cell(edit)="data">
          <b-button :disabled='!isEdit' @click="deleteRowHandler(data)" class="ml-1 mt-1 btn-sm"> 
            <span><feather type="trash-2"></feather></span>
          </b-button>
        </template>

      </b-table>
    </form>
  </b-modal>
  </div>
</template>

<script>
    export default {
        name: 'SubSymbol',
        props: ['subSymbols','serverRecords','symbolSettings','settingId','isEdit'],
        components: {
        },
        data() {
          return {
            state: false,
            validMsg: "Name is required",
            fields: [
              {
                  key:"serverName",
              },
              {
                  key:"symbol",
              },
              {
                  key:"edit",
              }
            ],
            newSubSymbols: []
          }
        },
        mounted() {
        },
        computed: {
          btnName() {
            return this.subSymbols.length + ' selected';
          },
          serverOptions() {
            return this.serverRecords.map(server => ( {value: server.id, text: server.serverName} ));
          },
          symbolOptions() {
            return this.serverRecords.map( serverRecord => ({serverId: serverRecord.id, 
              options: this.symbolSettings.filter(s=>s.serverId == serverRecord.id).map(s=>({value: s.symbol, text: s.symbol}))
              }));
          }
        },
        methods: {
          onclick() {
            let id = 1;
            this.newSubSymbols = this.subSymbols.map(setting => ({...setting, id: id++, isEdit: false}));
          },
          getSymbolOptions(serverId) {
            let found = this.symbolOptions.find(s=>s.serverId == serverId);
            if (found != undefined) return found.options;
            else return [];
          },
          checkFormValidity() {
          //todo check emptyness and uniqueness
          const valid = this.$refs.form.checkValidity()
          this.state = valid
          return valid;
          },
          resetModal() {
            this.state = null;
            this.validMsg = '';

          },
          handleOk(bvModalEvent) {
            if(!this.isEdit) return;
            // Prevent modal from closing
            bvModalEvent.preventDefault()
            // Trigger submit handler
            this.handleSubmit()
          },
          handleSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
              return
            }

            this.$emit('change', {id: this.settingId, settings : this.newSubSymbols});

            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide(this.settingId.toString());
            })
          },
          deleteRowHandler(data) {
            const indexOfObject = this.newSubSymbols.findIndex(object => 
            { return object.id === data.item.id;});
            this.newSubSymbols.splice(indexOfObject, 1);
          },
          addRowHandler() {
            let id = this.newSubSymbols.reduce((a,c) => c.id > a ? c.id : a, 0) + 1;
            this.newSubSymbols.unshift({ id:id, typeName: 'GOLD'+id, backColor:'black', foreColor:'gold' });
          },
        },
    }
</script>